// Return a clean redirect path, ensuring it's a valid URL with the same origin
function cleanRedirectPath(path: string | null): string | null {
  if (!path) return null;

  try {
    const cleanPath = path.replace(/^"|"$/g, '');
    const url = new URL(cleanPath, window.location.origin);

    if (url.origin !== window.location.origin) {
      return null;
    }

    const sensitiveParams = ['code', 'access_token', 'refresh_token', 'token'];

    if (sensitiveParams.some((param) => url.searchParams.has(param))) {
      return null;
    }

    const decodedPathname = decodeURIComponent(url.pathname);
    if (
      decodedPathname.startsWith('/login') ||
      decodedPathname.startsWith('/logout')
    ) {
      return null;
    }

    const fullPath = url.pathname + url.search + url.hash;
    return fullPath.startsWith('/') ? fullPath : null;
  } catch {
    return null;
  }
}

export function setRedirectSearchParam(value: string) {
  const url = new URL(window.location.href);
  const parsedPath = cleanRedirectPath(value);

  if (!parsedPath) {
    url.searchParams.delete('redirect');
  } else {
    url.searchParams.set('redirect', parsedPath);
  }
  window.history.pushState({}, '', url.toString());
}

export function getRedirectSearchParam(
  paramName: string = 'redirect',
): string | null {
  const url = new URL(window.location.href);
  const redirect = url.searchParams.get(paramName);
  return cleanRedirectPath(redirect);
}
