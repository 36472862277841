import { NullifyCollectionModel } from '@/models/common';
import { makeAutoObservable } from 'mobx';
import { z } from 'zod';
import {
  Installation,
  InstallationData,
  InstallationSchema,
} from './installation';

const InstallationsApiResponseSchema = z.object({
  installations: z.array(InstallationSchema),
});
export type InstallationsDTO = z.infer<typeof InstallationsApiResponseSchema>;

export class InstallationCollection
  implements NullifyCollectionModel<InstallationData>
{
  readonly _items: Installation[];

  private constructor(installationsData: InstallationsDTO) {
    makeAutoObservable(this);
    this._items = installationsData.installations.map(
      (installation) => new Installation(installation),
    );
  }

  get items() {
    return this._items;
  }

  get rawData() {
    return this.items.map((item) => item.rawData);
  }

  static async load(
    apiUrl: string,
    service_account_key?: string,
  ): Promise<InstallationCollection> {
    const response = await fetch(`${apiUrl}/admin/installations`, {
      credentials: 'include',
      headers: service_account_key
        ? { Authorization: `Bearer ${service_account_key}` }
        : {},
    });

    if (!response.ok) {
      throw new Error(
        `Failed to load installations from ${apiUrl}, status: ${response.status}`,
      );
    }

    const body = await response.json();
    const installationsData = InstallationsApiResponseSchema.parse(body);
    return new InstallationCollection(installationsData);
  }
}
