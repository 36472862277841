'use client';

import {
  isDevelopmentEnvironment,
  isProductionEnvironment,
} from '@/lib/environment';
import { Settings } from '@/models/settings';
import { makeAutoObservable } from 'mobx';

export class SettingsStore {
  _settings: Settings;

  private constructor(settings: Settings) {
    makeAutoObservable(this);
    this._settings = settings;
  }

  get settings() {
    return this._settings;
  }

  static async load() {
    const settings = await Settings.load();
    return new SettingsStore(settings);
  }

  get isDevelopment(): boolean {
    return isDevelopmentEnvironment(this._settings.rawData.apiUrl);
  }

  get isProduction(): boolean {
    return isProductionEnvironment(this._settings.rawData.apiUrl);
  }
}
